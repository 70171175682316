/**
 * Functional component for an "Log Out" SVG icon.
 * @param {Object} props - Component props.
 * @param {Function} props.handleClick - Click event handler.
 * @returns {JSX.Element} - Rendered SVG icon.
 */
const LogOut = ({ handleClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    onClick={handleClick}
    fill="currentColor">
    <path d="M6.26489 3.80698L7.41191 5.44558C5.34875 6.89247 4 9.28873 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 9.28873 18.6512 6.89247 16.5881 5.44558L17.7351 3.80698C20.3141 5.61559 22 8.61091 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.61091 3.68594 5.61559 6.26489 3.80698ZM11 12V2H13V12H11Z"></path>
  </svg>
);

export default LogOut;
