/**
 * Represents UTM parameters.
 * @typedef {Object} UtmParams
 * @property {string} name - The name of the UTM parameter.
 * @property {string} header - The header for UTM parameters.
 * @property {string} headerAlign - The alignment for the header (center, left, right).
 */

/**
 * UTM parameters configuration.
 * @const {UtmParams}
 */
const utm = { name: "utm", header: "UTM PARAMS", headerAlign: "center" };

/**
 * Represents shortened URL properties.
 * @typedef {Object} ShortenedUrlProps
 * @property {string} name - The name of the shortened URL property.
 * @property {string} header - The header for shortened URL.
 * @property {string} headerAlign - The alignment for the header (center, left, right).
 */

/**
 * Shortened URL properties configuration.
 * @const {ShortenedUrlProps}
 */
const shortened = {
  name: "shortened",
  header: "SHORTENED URL",
  headerAlign: "center",
};

/**
 * Represents target URL properties.
 * @typedef {Object} TargetUrlProps
 * @property {string} name - The name of the target URL property.
 * @property {string} header - The header for target URL.
 * @property {string} headerAlign - The alignment for the header (center, left, right).
 */

/**
 * Target URL properties configuration.
 * @const {TargetUrlProps}
 */
const target = { name: "target", header: "TARGET URL", headerAlign: "center" };

/**
 * Represents opened description properties.
 * @typedef {Object} OpenedDescProps
 * @property {string} name - The name of the opened description property.
 * @property {string} header - The header for opened description.
 * @property {string} headerAlign - The alignment for the header (center, left, right).
 */

/**
 * Opened description properties configuration.
 * @const {OpenedDescProps}
 */
const opened_desc = {
  name: "opened_desc",
  header: "OPENED",
  headerAlign: "center",
};

/**
 * Represents changed description properties.
 * @typedef {Object} ChangedDescProps
 * @property {string} name - The name of the opened description property.
 * @property {string} header - The header for opened description.
 * @property {string} headerAlign - The alignment for the header (center, left, right).
 */

/**
 * Opened description properties configuration.
 * @const {OpenedDescProps}
 */
const changed_desc = {
  name: "changed_desc",
  header: "LINK MODIFIED",
  headerAlign: "center",
};

/**
 * Represents created by properties.
 * @typedef {Object} CreatedByProps
 * @property {string} name - The name of the created by property.
 * @property {string} header - The header for created by.
 * @property {string} headerAlign - The alignment for the header (center, left, right).
 */

/**
 * Created by properties configuration.
 * @const {CreatedByProps}
 */
const created_by = {
  name: "created_by",
  header: "CREATED BY",
  headerAlign: "center",
};

/**
 * Groups of columns.
 * @typedef {Object} GroupProps
 * @property {UtmParams} utm - UTM parameters.
 * @property {ShortenedUrlProps} shortened - Shortened URL properties.
 * @property {TargetUrlProps} target - Target URL properties.
 * @property {OpenedDescProps} opened_desc - Opened description properties.
 * @property {CreatedByProps} created_by - Created by properties.
 */

/**
 * Group properties configuration.
 * @const {GroupProps}
 */
const groupProps = {
  utm,
  shortened,
  target,
  opened_desc,
  created_by,
  changed_desc,
};

export default groupProps;
