import { urls, getUrlHeaders, urlMethods } from "../config/urlConfig";

/**
 * Performs user login by making a request to the login endpoint.
 *
 * @param {Object} data - User credentials (mobile and password).
 * @param {string} data.mobile - User's mobile number.
 * @param {string} data.password - User's password.
 * @returns {Promise<Object>} - Resolves with an object containing the authentication token.
 * @throws {Error} - If the request fails or if the provided credentials are invalid.
 */
const login = async ({ data }) => {
  try {
    const response = await fetch(urls.LOGIN_URL, {
      method: urlMethods.POST,
      headers: getUrlHeaders(),
      body: JSON.stringify({
        mobile: data.mobile,
        password: data.password,
      }),
    });

    if (!response.ok) {
      throw new Error("Invalid details");
    }

    const result = await response.json();
    return { token: result.token };
  } catch (error) {
    console.error("Error during login:", error.message);
    throw error;
  }
};

export default login;
