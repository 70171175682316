import { getToken } from "../utils/commonUtils";

/**
 * Base URL for the API.
 */
const BASE_URL = "https://buynp.in";

/**
 * API URL for shortening operations.
 */
const API_URL = `${BASE_URL}/shortening`;

/**
 * URLs for various API endpoints.
 */
const urls = {
  /**
   * URL for retrieving a list of records.
   */
  DATA_LIST_URL: `${API_URL}/list_records/`,

  /**
   * URL for modifying a record.
   */
  DATA_EDIT_URL: `${API_URL}/modify_record/`,

  /**
   * URL for creating a record with authentication.
   */
  DATA_ADD_URL: `${API_URL}/create_record_with_auth/`,

  /**
   * URL for deleting a record.
   */
  DATA_DELETE_URL: `${API_URL}/delete_record/`,

  /**
   * URL for retrieving a list of UTM parameters.
   */
  UTM_PARAMS_LIST_URL: `${API_URL}/list_utms/`,

  /**
   * URL for user login.
   */
  LOGIN_URL: `${API_URL}/login/`,
};

/**
 * Function to get headers for API requests.
 * @returns {Object} Headers object.
 */
const getUrlHeaders = () => {
  const token = getToken();

  /**
   * Headers object for API requests.
   * @type {Object}
   */
  const headers = {
    "Content-Type": "application/json",
  };

  if (token != null) {
    headers["Authorization"] = token;
  }

  return headers;
};

/**
 * Object containing HTTP methods.
 * @type {Object}
 */
const urlMethods = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export { urls, getUrlHeaders, urlMethods };
