import { urls, getUrlHeaders, urlMethods } from "../config/urlConfig";
import { responseCheck } from "../utils/commonUtils";

/**
 * Update data by sending a PATCH request to the server.
 *
 * @param {Object} options - The options for updating the data.
 * @param {string} options.id - The ID of the data to be updated.
 * @param {Object} options.data - The data to be updated.
 * @param {string} options.data.target_url - The target URL for the data.
 * @param {string} options.data.name - The name of the data.
 * @param {string} options.data.source - The source of the data.
 * @param {string} options.data.medium - The medium of the data.
 * @param {string} options.data.campaign - The campaign of the data.
 * @param {string} options.data.remarks - Any remarks for the data.
 */
const updateData = async ({ id, data }) => {
  try {
    const response = await fetch(`${urls.DATA_EDIT_URL}${id}/`, {
      method: urlMethods.PATCH,
      headers: getUrlHeaders(),
      body: JSON.stringify({ 
        target_url: data.target_url,
        name: data.name,
        source: data.source,
        medium: data.medium,
        campaign: data.campaign,
        remarks: data.remarks
      }),
    });
    responseCheck({ response });
  } catch (error) {
    console.error("Error during patch request:", error.message);
    throw new Error("Modification Failed");
  }
};

export default updateData;
