import { urls, getUrlHeaders, urlMethods } from "../config/urlConfig";
import { responseCheck } from "../utils/commonUtils";

/**
 * Adds data using a POST request to the specified URL.
 * @param {Object} data - The data to be added.
 * @param {string} data.target_url - The target URL.
 * @param {string} data.name - The name associated with the data.
 * @param {string} data.source - The source of the data.
 * @param {string} data.medium - The medium through which the data is collected.
 * @param {string} data.campaign - The campaign related to the data.
 * @param {string} data.remarks - Additional remarks about the data.
 * @returns {Promise<void>} - A Promise that resolves when the data is successfully added.
 * @throws {Error} - If there is an error during the POST request.
 */
const addData = async ({ data }) => {
  try {
    const response = await fetch(urls.DATA_ADD_URL, {
      method: urlMethods.POST,
      headers: getUrlHeaders(),
      body: JSON.stringify({
        target_url: data.target_url,
        name: data.name,
        source: data.source,
        medium: data.medium,
        campaign: data.campaign,
        remarks: data.remarks,
        created_through: "Shortener Admin",
      }),
    });
    responseCheck({ response });
  } catch (error) {
    console.error("Error during post request:", error.message);
    throw error;
  }
};

export default addData;
