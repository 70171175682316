import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "rsuite";
import styles from "./DeleteAlert.module.css";
import deleteData from "../../services/deleteObject";

/**
 * DeleteAlert component for confirming deletion of an entry.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Flag to determine if the modal is open.
 * @param {Function} props.handleClose - Callback to handle modal closure.
 * @param {Function} props.refreshData - Callback to refresh data after deletion.
 * @param {string} props.objId - ID of the object to be deleted.
 * @returns {JSX.Element} - The rendered DeleteAlert component.
 */
const DeleteAlert = ({ open, handleClose, refreshData, objId }) => {
  /**
   * Handles the deletion process.
   * Closes the modal, deletes the object, and refreshes the data.
   */
  const handleDelete = async () => {
    try {
      handleClose();
      await deleteData({ id: objId });
      refreshData();
    } catch (error) {
      console.error("Error deleting object:", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} size="xs">
      <Modal.Body className={styles.alertbody}>
        Sure you want to delete the selected entry?
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={styles.delete_ok_btn}
          onClick={handleDelete}
          appearance="subtle">
          Ok
        </Button>
        <Button
          className={styles.delete_cancel_btn}
          onClick={handleClose}
          appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Prop types validation
DeleteAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  objId: PropTypes.string.isRequired,
};

export default DeleteAlert;
