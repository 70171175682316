import colFilterProps from "../subDataParts/columnFilterProps";

/**
 * List of column filter values.
 * Predefined filter values for different columns.
 */
const filterVals = [
  colFilterProps.shortened_url,
  colFilterProps.target_url,
  colFilterProps.name,
  colFilterProps.source,
  colFilterProps.medium,
  colFilterProps.campaign,
  colFilterProps.opened,
  colFilterProps.created_at,
  colFilterProps.creator_name,
  colFilterProps.creator_mobile,
  colFilterProps.created_through,
  colFilterProps.remarks,
];

export default filterVals;
