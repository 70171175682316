import { urls, getUrlHeaders, urlMethods } from "../config/urlConfig";
import { responseCheck } from "../utils/commonUtils";

/**
 * Deletes data with the specified ID from the server.
 *
 * @param {Object} options - The options object.
 * @param {string} options.id - The ID of the data to be deleted.
 * @throws {Error} Throws an error if the delete request fails.
 */
const deleteData = async ({ id }) => {
  try {
    const deleteUrl = `${urls.DATA_DELETE_URL}${id}/`;
    const response = await fetch(deleteUrl, {
      method: urlMethods.DELETE,
      headers: getUrlHeaders(),
    });
    responseCheck({ response });
  } catch (error) {
    console.error("Error during delete request:", error.message);
  }
};

export default deleteData;
