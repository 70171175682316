import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/theme/green-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import PaginationToolbar from "@inovua/reactdatagrid-community/packages/PaginationToolbar";
import { alterGridFilterOptions } from "../../utils/commonUtils";

/**
 * Styling for the grid component.
 */
const gridStyle = {
  height: "calc(100vh - 86px)",
  marginTop: 0,
  fontSize: 12.5,
};

/**
 * Styling for the grid header.
 */
const headerProps = {
  style: {
    fontSize: 12,
  },
};

/**
 * Configuration for grid scrolling.
 */
const scrollProps = {
  autoHide: false,
  scrollThumbWidth: 14,
  scrollThumbStyle: {
    backgroundColor: "#2f3740",
    opacity: "50%",
  },
};

/**
 * A React component wrapping the DataGrid with additional features.
 *
 * @param {Object} props - The component's properties.
 * @param {Array} props.dataSource - The data source for the grid.
 * @param {Array} props.columns - The columns configuration for the grid.
 * @param {string} props.defaultFilterValue - The default filter value.
 * @param {Array} props.groups - The groups configuration for the grid.
 * @param {number} props.skip - The number of items to skip in pagination.
 * @param {Function} props.onSkipChange - Callback for changing skip value.
 * @param {number} props.limit - The limit of items per page in pagination.
 * @param {Function} props.onLimitChange - Callback for changing limit value.
 * @param {Function} props.onFilterValueChange - Callback for changing filter value.
 * @param {number} props.dataCountForPage - The count of data for the current page.
 * @returns {JSX.Element} - The wrapped DataGrid component.
 */
const WrappedDataGrid = ({
  dataSource,
  columns,
  defaultFilterValue,
  groups,
  skip,
  onSkipChange,
  limit,
  onLimitChange,
  onFilterValueChange,
  dataCountForPage,
}) => {
  useEffect(() => alterGridFilterOptions(ReactDataGrid), []);
  return (
    <ReactDataGrid
      style={gridStyle}
      theme="green-light"
      idProperty="id"
      dataSource={dataSource}
      columns={columns}
      editable={false}
      pagination="remote"
      defaultFilterValue={defaultFilterValue}
      renderPaginationToolbar={(paginationProps) => {
        return (
          <PaginationToolbar
            {...paginationProps}
            count={dataCountForPage}
            totalCount={dataCountForPage}
            skip={skip}
            onSkipChange={onSkipChange}
            limit={limit}
            onLimitChange={onLimitChange}
            bordered={false}
            onRefresh={null}
          />
        );
      }}
      showColumnMenuTool={false}
      headerProps={headerProps}
      scrollProps={scrollProps}
      groups={groups}
      onFilterValueChange={onFilterValueChange}
      // filterValue={filterFillVals}
    />
  );
};

// Define PropTypes for type checking
WrappedDataGrid.propTypes = {
  dataSource: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  defaultFilterValue: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  skip: PropTypes.number.isRequired,
  onSkipChange: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onFilterValueChange: PropTypes.func.isRequired,
  dataCountForPage: PropTypes.number.isRequired,
};

export default WrappedDataGrid;
