import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styles from "./AddEditForm.module.css";
import updateData from "../../services/updateObject";
import addData from "../../services/addObject";

const nameOptions = [
  "Order Tracking",
  "Quality Report",
  "Spectro Reading",
  "Add Dil Suggestion",
  "Other",
];

const AddEditForm = ({
  handleModalClose,
  refreshData,
  isAdding,
  initValues: {
    id = "",
    target_url = "",
    name = "",
    source = "",
    medium = "",
    campaign = "",
    remarks = "",
  } = {},
}) => (
  <Formik
    initialValues={{
      target_url,
      name,
      source,
      medium,
      campaign,
      remarks,
    }}
    validate={(values) => {
      const errors = {};
      if (!values.target_url) {
        errors.target_url = "   (Required)";
      }
      if (!values.name) {
        errors.name = "   (Required)";
      }
      return errors;
    }}
    onSubmit={(values, { setSubmitting, setErrors }) => {
      const handleSubmission = isAdding ? addData : updateData;
      handleSubmission({ id, data: values })
        .then(() => {
          setSubmitting(false);
          handleModalClose();
          refreshData();
        })
        .catch((error) => {
          console.log(error);
        });
    }}>
    {({
      isSubmitting,
      errors,
      touched,
      isValid,
      setFieldTouched,
      handleChange,
    }) => {
      const handleChangeAndTouch = (e) => {
        handleChange(e);
        setFieldTouched(e.target.name, true, false);
      };

      return (
        <Form className={styles.addform}>
          <div>
            <label className={styles.addform__label} htmlFor="target_url">
              Target Link <span style={{ color: "red" }}>*</span>
              <ErrorMessage
                className={styles.addform__error}
                name="target_url"
                component="label"
              />
            </label>
            <Field
              className={`${styles.addform__textarea} ${
                errors.target_url && touched.target_url ? styles.error : ""
              }`}
              as="textarea"
              type="text"
              name="target_url"
              onChange={handleChangeAndTouch}
            />
          </div>

          <div>
            <label className={styles.addform__label} htmlFor="name">
              Link Type <span style={{ color: "red" }}>*</span>
              <ErrorMessage
                className={styles.addform__error}
                name="name"
                component="label"
              />
            </label>
            <Field
              className={styles.addform__select}
              as="select"
              name="name"
              onChange={handleChangeAndTouch}>
              <option value="" disabled defaultValue>
                Select an option
              </option>
              {nameOptions.map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </Field>
          </div>

          <div>
            <label className={styles.addform__label} htmlFor="source">
              Utm Source
            </label>
            <Field
              className={styles.addform__input}
              type="text"
              name="source"
              onChange={handleChangeAndTouch}
            />
          </div>

          <div>
            <label className={styles.addform__label} htmlFor="medium">
              Utm Medium
            </label>
            <Field
              className={styles.addform__input}
              type="text"
              name="medium"
              onChange={handleChangeAndTouch}
            />
          </div>

          <div>
            <label className={styles.addform__label} htmlFor="campaign">
              Utm Campaign
            </label>
            <Field
              className={styles.addform__input}
              type="text"
              name="campaign"
              onChange={handleChangeAndTouch}
            />
          </div>

          <div>
            <label className={styles.addform__label} htmlFor="remarks">
              Remarks / Desc / Purpose
            </label>
            <Field
              className={styles.addform__textarea}
              as="textarea"
              type="text"
              name="remarks"
              onChange={handleChangeAndTouch}
            />
          </div>

          <div>
            <button
              className={styles.addform__button}
              type="submit"
              disabled={
                !isValid ||
                (!Object.keys(touched).length && isAdding) ||
                isSubmitting
              }>
              {isAdding ? "ADD" : "UPDATE"}
            </button>
            <div>
            </div>
          </div>
        </Form>
      );
    }}
  </Formik>
);

// Prop types
AddEditForm.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  initValues: PropTypes.shape({
    id: PropTypes.string,
    target_url: PropTypes.string,
    name: PropTypes.string,
    source: PropTypes.string,
    medium: PropTypes.string,
    campaign: PropTypes.string,
    remarks: PropTypes.string,
  }),
};

export default AddEditForm;
