import React from "react";
import PropTypes from "prop-types";
import AddLink from "../microComponents/AddLink";
import LogOut from "../microComponents/LogOut";
import styles from "./Topbar.module.css";

/**
 * Functional component representing the TopBar.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.handleOpen - Function to handle opening link creation.
 * @param {Object} props.searchValue - Ref for the search input.
 * @param {Function} props.applyFilterRefreshData - Function to apply filters and refresh data.
 * @param {Function} props.clearFilterRefreshData - Function to clear filters and refresh data.
 * @param {Function} props.handleLogoutOpen - Function to handle opening logout.
 * @returns {JSX.Element} - The rendered component.
 */
const TopBar = ({
  handleOpen,
  searchValue,
  applyFilterRefreshData,
  clearFilterRefreshData,
  handleLogoutOpen,
}) => {
  return (
    <div className={styles.TopBar_container}>
      <div className={styles.TopBar}>
        {/* Title */}
        <div className={styles.Topbar_title}>LinkShorten</div>

        {/* Add Link Button */}
        <div className={styles.Topbar_addlink}>
          <AddLink handleClick={handleOpen} />
        </div>

        {/* Search Text Input */}
        <div className={styles.Topbar_searchtext}>
          SEARCH TEXT{" "}
          <input
            className={styles.Topbar_searchtext_inputbox}
            ref={searchValue}
          />
        </div>

        {/* Apply Filters Button */}
        <div className={styles.Topbar_buttonbox}>
          <button
            className={styles.Topbar_applyButton}
            onClick={applyFilterRefreshData}
          >
            Apply Filters
          </button>
        </div>

        {/* Logout Link */}
        <div className={styles.Topbar_logoutlink}>
          <LogOut handleClick={handleLogoutOpen} />
        </div>
      </div>
    </div>
  );
};

// PropTypes for type-checking
TopBar.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  searchValue: PropTypes.object.isRequired,
  applyFilterRefreshData: PropTypes.func.isRequired,
  clearFilterRefreshData: PropTypes.func.isRequired,
  handleLogoutOpen: PropTypes.func.isRequired,
};

export default TopBar;
