import { createRowsFromData } from "../utils/commonUtils";
import { urls, getUrlHeaders, urlMethods } from "../config/urlConfig";
import { responseCheck } from "../utils/commonUtils";

/**
 * Fetch data from the server based on specified parameters.
 * @param {Object} params - The parameters for the data request.
 * @param {number} params.skip - The number of items to skip.
 * @param {number} params.limit - The maximum number of items to retrieve.
 * @param {string} params.search - The search query.
 * @param {Object} params.filter - The filter criteria.
 * @returns {Promise<Object>} - A promise that resolves to an object containing data and count.
 */
const fetchData = async ({ skip, limit, search, filter }) => {
  try {
    const response = await fetch(urls.DATA_LIST_URL, {
      method: urlMethods.POST,
      headers: getUrlHeaders(),
      body: JSON.stringify({ skip, limit, search, filter }),
    });
    responseCheck({response});

    const responseData = await response.json();
    const resultData = {
      data: createRowsFromData(responseData.data, skip),
      count: responseData.count,
    };
    return resultData;
  } catch (error) {
    console.error("Error during post request:", error.message);
    return {
      data: [],
      count: 0
    };
  }
};

export default fetchData;
