import React from "react";
import PropTypes from "prop-types";
import { Modal } from "rsuite";
import AddEditForm from "./AddEditForm";
import styles from "./AddModal.module.css";

/**
 * Functional component for displaying an add/edit modal.
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.open - Flag to control modal visibility
 * @param {Function} props.handleClose - Function to handle modal close
 * @param {Function} props.refreshData - Function to refresh data after adding/editing
 * @param {Object} props.initialValues - Initial values for the form fields
 */
const AddModal = ({ open, handleClose, refreshData, initialValues = {} }) => {
  /**
   * Checks if an object is empty.
   *
   * @function
   * @returns {boolean} - True if the object is empty, false otherwise
   */
  const isObjEmpty = () => Object.keys(initialValues).length === 0;

  return (
    <Modal open={open} onClose={handleClose} size="600px">
      <Modal.Header className={styles.Modal__Header}>
        <Modal.Title className={styles.Modal__Title}>
          {isObjEmpty() ? (
            <span>Add New Link</span>
          ) : (
            <span>
              Update Link{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={initialValues.shortened_url || "#"}
                className={styles.shortened_link_header}
              >
                {initialValues.shortened_url || ""}
              </a>
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.Modal__Body}>
        <AddEditForm
          handleModalClose={handleClose}
          isAdding={isObjEmpty()}
          refreshData={refreshData}
          initValues={initialValues}
        />
      </Modal.Body>
    </Modal>
  );
};

// Prop types validation
AddModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default AddModal;
