import React, { useState, useRef, useEffect } from "react";
import fetchData from "../services/getTableData";
import {
  updateUtmLists,
  updateEditDeleteActions,
} from "../utils/tableCellUtils";

import styles from "./App.module.css";
import "./App.css";

import TopBar from "../components/macroComponents/TopBar";
import WrappedDataGrid from "../components/macroComponents/WrappedDataGrid";
import AddModal from "../components/macroComponents/AddModal";
import DeleteAlert from "../components/macroComponents/DeleteAlert";
import LogoutAlert from "../components/macroComponents/LogoutAlert";
import DataToolTip from "../components/microComponents/DataToolTip";

import columns from "../dataparts/mainDataParts/column";
import groups from "../dataparts/mainDataParts/columnGroups";
import filterVals from "../dataparts/mainDataParts/columnFilters";

/**
 * Main application component.
 * @returns {JSX.Element} The App component.
 */
const App = () => {
  // State Hooks
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [logoutAlertOpen, setLogoutAlertOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [dataCountForPage, setDataCountForPage] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  // Refs
  const gridColumns = useRef(columns);
  const searchValue = useRef(null);
  const filterValue = useRef(filterVals);
  const setFilterValue = (value) => {
    filterValue.current = value;
  };

  /**
   * Updates the data source based on applied filters and search criteria.
   * @param {Object} params - Parameters for data fetching.
   */
  const refreshData = async ({ skip, limit, search, filter }) => {
    console.log(skip, limit, search, filter);
    try {
      const dropdownUpdatedCols = await updateUtmLists({ cols: columns });
      const actionUpdatedCols = updateEditDeleteActions({
        cols: dropdownUpdatedCols,
        editInitValSetter: setFormInitialValues,
        editModalOpenner: setAddModalOpen,
        deleteIdSetter: setDeleteId,
        deleteAlertOpenner: setDeleteAlertOpen,
      });
      gridColumns.current = actionUpdatedCols;
      const resultData = await fetchData({ skip, limit, search, filter });
      setDataSource(resultData.data);
      setDataCountForPage(resultData.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  /**
   * Applies filters and refreshes the data source.
   */
  const applyFilterRefreshData = () => {
    const searchValToApply = searchValue.current?.value || "";
    const FilterValToApply = filterValue.current;
    setSkip(0);
    refreshData({
      skip,
      limit,
      search: searchValToApply,
      filter: FilterValToApply,
    });
  };

  // Effect Hook for initial data loading and updates
  useEffect(() => {
    const searchValToApply = searchValue.current?.value || "";
    const FilterValToApply = filterValue.current;
    refreshData({
      skip,
      limit,
      search: searchValToApply,
      filter: FilterValToApply,
    });
  }, [skip, limit]);

  // Main Component JSX
  return (
    <div className={styles.App}>
      <div className={styles.Container}>
        <TopBar
          handleOpen={() => {
            setAddModalOpen(true);
            setFormInitialValues({});
          }}
          searchValue={searchValue}
          applyFilterRefreshData={applyFilterRefreshData}
          clearFilterRefreshData={() => {}}
          handleLogoutOpen={() => setLogoutAlertOpen(true)}
        />
        <WrappedDataGrid
          dataSource={() => Promise.resolve({ data: dataSource, count: 19 })}
          columns={gridColumns.current}
          defaultFilterValue={filterVals}
          groups={groups}
          skip={skip}
          onSkipChange={setSkip}
          limit={limit}
          onLimitChange={setLimit}
          onFilterValueChange={setFilterValue}
          dataCountForPage={dataCountForPage}
        />
        <DataToolTip />
        <AddModal
          open={addModalOpen}
          handleClose={() => setAddModalOpen(false)}
          refreshData={applyFilterRefreshData}
          initialValues={formInitialValues}
        />
        <DeleteAlert
          open={deleteAlertOpen}
          handleClose={() => setDeleteAlertOpen(false)}
          refreshData={applyFilterRefreshData}
          objId={deleteId}
        />
        <LogoutAlert
          open={logoutAlertOpen}
          handleClose={() => setLogoutAlertOpen(false)}
        />
      </div>
    </div>
  );
};

export default App;
