import React from "react";
import { Tooltip } from "react-tooltip";
import styles from "./DataToolTip.module.css";

/**
 * A reusable tooltip component for displaying data.
 * @component
 * @returns {JSX.Element} - Tooltip component with custom styles.
 */
const DataToolTip = () => (
  <Tooltip
    id="my-tooltip"
    className={styles.ToolTip}
    openEvents={{ click: true }}
  />
);

export default DataToolTip;
