import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styles from "./LoginPage.module.css";
import login from "../services/login";
import { handleLogin } from "../utils/commonUtils";

/**
 * Component for rendering the login page.
 * @returns {JSX.Element} The login page component.
 */
const LoginPage = () => {
  /**
   * Handler for form submission.
   * @param {Object} values - Form values.
   * @param {Object} formActions - Formik form actions.
   */
  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    login({ data: values })
      .then((result) => {
        handleLogin({ token: result.token, user: values.mobile });
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ generic: "Invalid credentials. Try again." });
      });
  };

  /**
   * Handler for input change and touch event.
   * @param {Object} e - Change event object.
   * @param {Function} handleChange - Formik's handleChange function.
   * @param {Function} setFieldTouched - Formik's setFieldTouched function.
   */
  const handleChangeAndTouch = (e, handleChange, setFieldTouched) => {
    handleChange(e);
    setFieldTouched(e.target.name, true, false);
  };

  return (
    <div className={styles.LoginPage}>
      <Formik
        initialValues={{
          mobile: "",
          password: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.mobile) {
            errors.mobile = "   (Reqd.)";
          }
          if (!values.password) {
            errors.password = "   (Reqd.)";
          }
          return errors;
        }}
        onSubmit={handleSubmit}>
        {({
          isSubmitting,
          errors,
          touched,
          isValid,
          setFieldTouched,
          handleChange,
        }) => (
          <Form className={styles.LoginForm}>
            <div className={styles.LoginForm__head}>Log In</div>
            <div>
              <label className={styles.LoginForm__label} htmlFor="mobile">
                Mobile <span style={{ color: "red" }}>*</span>
                <ErrorMessage
                  className={styles.LoginForm__error}
                  name="mobile"
                  component="label"
                />
              </label>
              <Field
                className={`${styles.LoginForm__input} ${
                  errors.mobile && touched.mobile ? styles.error : ""
                }`}
                as="input"
                type="text"
                name="mobile"
                onChange={(e) =>
                  handleChangeAndTouch(e, handleChange, setFieldTouched)
                }
              />
            </div>
            <div>
              <label className={styles.LoginForm__label} htmlFor="password">
                Password <span style={{ color: "red" }}>*</span>
                <ErrorMessage
                  className={styles.LoginForm__error}
                  name="password"
                  component="label"
                />
              </label>
              <Field
                className={`${styles.LoginForm__input} ${
                  errors.password && touched.password ? styles.error : ""
                }`}
                as="input"
                type="password"
                name="password"
                onChange={(e) =>
                  handleChangeAndTouch(e, handleChange, setFieldTouched)
                }
              />
            </div>
            <div className={styles.LoginForm__error_generic}>
              {errors.generic && <label>{errors.generic}</label>}
            </div>
            <div>
              <button
                className={styles.LoginForm__button}
                type="submit"
                disabled={
                  !isValid || !Object.keys(touched).length || isSubmitting
                }>
                SUBMIT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginPage;
