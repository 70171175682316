import groupProps from "../subDataParts/columnGroupProps";

/**
 * Predefined column groups for data representation.
 */
const groups = [
  groupProps.utm,
  groupProps.shortened,
  groupProps.target,
  groupProps.opened_desc,
  groupProps.changed_desc,
  groupProps.created_by,
];

export default groups;
