/**
 * Copies the provided text to the clipboard using the Clipboard API.
 * @param {string} text - The text to be copied to the clipboard.
 * @returns {Promise<void>} - A Promise that resolves when the text is copied.
 */
const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error("Error copying to clipboard:", error);
  }
};

/**
 * Creates rows with serial numbers from the given data array.
 * @param {Array<Object>} data - The data array to be transformed into rows.
 * @param {number} skip - The starting serial number.
 * @returns {Array<Object>} - An array of rows with serial numbers.
 */
const createRowsFromData = (data, skip) =>
  data.map((item, index) => ({
    slno: skip + index + 1,
    ...item,
  }));

/**
 * Handles the login operation by storing the provided token in the local storage
 * and redirecting the user to the home page ("/").
 * @param {string} newToken - The token to be stored in the local storage.
 * @returns {void}
 */
const handleLogin = ({token, user}) => {
  localStorage.setItem("tokenv2", token);
  localStorage.setItem("userv2", user);
  window.location.href = "/";
};

/**
 * Handles the logout operation by removing the token from the local storage
 * and redirecting the user to the login page ("/login").
 * @returns {void}
 */
const handleLogout = () => {
  localStorage.removeItem("tokenv2");
  localStorage.removeItem("userv2");
  window.location.href = "/login";
};

/**
 * Retrieves the stored token from the local storage.
 * @returns {string | null} - The formatted token or null
 */
const getToken = () => {
  const storedTokenVal = localStorage.getItem("tokenv2");
  if (!storedTokenVal) {
    return null;
  }
  return `${storedTokenVal}`;
};

/**
 * Checks the status of a network response and performs actions accordingly.
 * If the response status is 401, initiates a logout operation.
 * If the response status is not ok (not within the 2xx range), throws an Error.
 * @param {Object} options - An object containing the response property.
 * @param {Response} options.response - The network response object.
 * @throws {Error} - Throws an Error if the response status is not ok.
 * @returns {void}
 */
const responseCheck = ({ response }) => {
  if (response.status === 401) {
    handleLogout();
  } else if (!response.ok) {
    throw new Error("Network response was not ok");
  }
};

/**
 * Modifies the default filter options of a ReactGrid component based on specified filter options.
 *
 * @param {Object} ReactGrid - The ReactGrid component.
 * @returns {void}
 */
const alterGridFilterOptions = (ReactGrid) => {
  /**
   * The filter options to be applied for different data types.
   *
   * @type {Object}
   */
  console.log("XXX");
  const filterOptions = {
    string: ["contains", "notContains", "eq", "neq", "startsWith", "endsWith"],
    number: ["gt", "gte", "eq", "neq", "lt", "lte"],
    boolean: ["eq", "neq"],
    select: ["eq", "neq", "inlist", "notinlist"],
    date: [
      // "after",
      // "afterOrOn",
      // "before",
      // "beforeOrOn",
      "inrange",
      "notinrange",
      // "eq",
      // "neq",
    ],
  };

  for (const key in filterOptions) {
    ReactGrid.defaultProps.filterTypes[key].operators =
      ReactGrid.defaultProps.filterTypes[key].operators.filter((x) =>
        filterOptions[key].includes(x.name)
      );
  }
};

export {
  copyToClipboard,
  createRowsFromData,
  handleLogin,
  handleLogout,
  getToken,
  responseCheck,
  alterGridFilterOptions,
};
