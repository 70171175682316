import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "rsuite";
import styles from "./DeleteAlert.module.css";
import { handleLogout } from "../../utils/commonUtils";

/**
 * LogoutAlert component for displaying a confirmation modal before logging out.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {boolean} props.open - Flag indicating whether the modal is open.
 * @param {Function} props.handleClose - Function to handle modal closure.
 * @returns {JSX.Element} - Rendered component.
 */
const LogoutAlert = ({ open, handleClose }) => {
  /**
   * Handles the user's decision to log out.
   * Closes the modal and triggers the logout process.
   */
  const handleLogoutConfirmation = () => {
    handleClose();
    handleLogout();
  };

  return (
    <Modal open={open} onClose={handleClose} size="xs">
      <Modal.Body className={styles.alertbody}>
        Sure you want to log out?
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={styles.delete_ok_btn}
          onClick={handleLogoutConfirmation}
          appearance="subtle"
        >
          Ok
        </Button>
        <Button
          className={styles.delete_cancel_btn}
          onClick={handleClose}
          appearance="subtle"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Prop type validation
LogoutAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LogoutAlert;
