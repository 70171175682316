import React from "react";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";

import CopyLink from "../../components/microComponents/CopyLink";
import GotoLink from "../../components/microComponents/GotoLink";

import {
  createdAtFormatter,
  openedFirstFormatter,
  openedChangedDateFormatter,
} from "../../utils/tableCellUtils";

/**
 * Column configuration for SL. NO.
 */
const slno = {
  name: "slno",
  header: "SL. NO.",
  defaultWidth: 100,
  textAlign: "center",
  sortable: false,
};

/**
 * Column configuration for ACTION related to shortened URL.
 */
const shortened_url_action = {
  name: "shortened_url_action",
  header: "ACTION",
  group: "shortened",
  defaultWidth: 75,
  editable: true,
  textAlign: "center",
  sortable: false,
  render: ({ data }) => (
    <>
      <CopyLink link={data.shortened_url} />
      {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a
        href={data.shortened_url}
        rel="noreferrer"
        target="_blank"
        style={{color: "#474"}}>
        <GotoLink />
      </a> */}
    </>
  ),
};

/**
 * Column configuration for LINK related to shortened URL.
 */
const shortened_url = {
  name: "shortened_url",
  header: "LINK",
  group: "shortened",
  defaultWidth: 200,
  editable: false,
  headerAlign: "center",
  sortable: false,
};

/**
 * Column configuration for NAME.
 */
const name = {
  name: "name",
  header: "LINK TYPE",
  defaultWidth: 130,
  editable: false,
  headerAlign: "center",
  sortable: false,
};

/**
 * Column configuration for ACTION related to target URL.
 */
const target_url_action = {
  name: "target_url_action",
  header: "ACTION",
  group: "target",
  defaultWidth: 90,
  editable: false,
  textAlign: "center",
  sortable: false,
  render: ({ data }) => (
    <>
      <CopyLink link={data.target_url} />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a
        href={data.target_url}
        rel="noreferrer"
        target="_blank"
        style={{color: "#474"}}>
        <GotoLink />
      </a>
    </>
  ),
};

/**
 * Column configuration for LINK related to target URL.
 */
const target_url = {
  name: "target_url",
  header: "LINK",
  group: "target",
  defaultWidth: 350,
  editable: false,
  headerAlign: "center",
  sortable: false,
};

/**
 * Column configuration for SOURCE.
 */
const source = {
  name: "source",
  header: "SOURCE",
  defaultWidth: 180,
  editable: false,
  group: "utm",
  headerAlign: "center",
  filterEditor: SelectFilter,
  sortable: false,
  filterEditorProps: {
    multiple: true,
    wrapMultiple: false,
    dataSource: [],
  },
};

/**
 * Column configuration for MEDIUM.
 */
const medium = {
  name: "medium",
  header: "MEDIUM",
  defaultWidth: 180,
  editable: false,
  group: "utm",
  headerAlign: "center",
  sortable: false,
  filterEditor: SelectFilter,
  filterEditorProps: {
    multiple: true,
    wrapMultiple: false,
    dataSource: [],
  },
};

/**
 * Column configuration for CAMPAIGN.
 */
const campaign = {
  name: "campaign",
  header: "CAMPAIGN",
  defaultWidth: 180,
  editable: false,
  group: "utm",
  headerAlign: "center",
  sortable: false,
  filterEditor: SelectFilter,
  filterEditorProps: {
    multiple: true,
    wrapMultiple: false,
    dataSource: [],
  },
};

/**
 * Column configuration for STATUS.
 */
const opened = {
  name: "opened",
  header: "STATUS",
  group: "opened_desc",
  defaultWidth: 120,
  editable: false,
  textAlign: "center",
  sortable: false,
  filterEditor: SelectFilter,
  filterEditorProps: {
    multiple: false,
    dataSource: [
      { id: true, label: "Yes" },
      { id: false, label: "No" },
    ],
  },
  render: ({ data }) =>
    Boolean(data.opened_dates?.length) ? (
      <label style={{ color: "#6A6" }}>Yes</label>
    ) : (
      <label style={{ color: "#A66" }}>No</label>
    ),
};

/**
 * Column configuration for FIRST opened date.
 */
const opened_first = {
  name: "opened_first",
  header: "FIRST",
  group: "opened_desc",
  defaultWidth: 170,
  editable: false,
  headerAlign: "center",
  sortable: false,
  render: ({ data }) => openedFirstFormatter({ dates: data.opened_dates }),
};

/**
 * Column configuration for LAST opened date.
 */
const opened_last = {
  name: "opened_last",
  header: "LAST",
  group: "opened_desc",
  defaultWidth: 170,
  editable: false,
  headerAlign: "center",
  sortable: false,
  render: ({ data }) => openedChangedDateFormatter({ dates: data.opened_dates }),
};

/**
 * Column configuration for NUMBER OF TIMES opened.
 */
const opened_times = {
  name: "opened_times",
  header: "TIMES",
  group: "opened_desc",
  defaultWidth: 70,
  editable: false,
  headerAlign: "center",
  sortable: false,
  render: ({ data }) => data.opened_dates?.length || "",
};

/**
 * Column configuration for CREATOR NAME.
 */
const creator_name = {
  name: "creator_name",
  header: "NAME",
  group: "created_by",
  defaultWidth: 180,
  editable: false,
  headerAlign: "center",
  sortable: false,
};

/**
 * Column configuration for CREATOR MOBILE.
 */
const creator_mobile = {
  name: "creator_mobile",
  header: "MOBILE",
  group: "created_by",
  defaultWidth: 120,
  editable: false,
  headerAlign: "center",
  sortable: false,
};

/**
 * Column configuration for CREATED AT date.
 */
const created_at = {
  name: "created_at",
  header: "CREATED AT",
  defaultWidth: 320,
  editable: false,
  textAlign: "center",
  dateFormat: "YYYY-MM-DD",
  sortable: false,
  filterEditor: DateFilter,
  filterEditorProps: (props, { index }) => ({
    dateFormat: "YYYY-MM-DD",
    placeholder: index === 0 ? "start" : "end",
  }),
  render: ({ value, cellProps: { dateFormat } }) =>
    createdAtFormatter({ value }),
};

/**
 * Column configuration for CREATED THROUGH platform.
 */
const created_through = {
  name: "created_through",
  header: "PLATFORM",
  defaultWidth: 180,
  editable: false,
  headerAlign: "center",
  sortable: false,
};

/**
 * Column configuration for LAST changed date.
 */
const changed_last = {
  name: "changed_last",
  header: "LAST",
  group: "changed_desc",
  defaultWidth: 170,
  editable: false,
  headerAlign: "center",
  sortable: false,
  render: ({ data }) => openedChangedDateFormatter({ dates: data.changed_dates }),
};

/**
 * Column configuration for NUMBER OF TIMES changed.
 */
const changed_times = {
  name: "changed_times",
  header: "TIMES",
  group: "changed_desc",
  defaultWidth: 70,
  editable: false,
  headerAlign: "center",
  sortable: false,
  render: ({ data }) => data.changed_dates?.length || "",
};

/**
 * Column configuration for REMARKS.
 */
const remarks = {
  name: "remarks",
  header: "REMARKS / DESCRIPTION / PURPOSE",
  defaultWidth: 400,
  editable: false,
  headerAlign: "center",
  sortable: false,
};

/**
 * Column configuration for ACTION at the end.
 */
const action = {
  name: "action",
  header: "ACTION",
  defaultWidth: 100,
  editable: false,
  textAlign: "center",
  sortable: false,
  render: ({ data }) => (
    <></>
  ),
};

/**
 * Column configurations for the entire table.
 */
const colProps = {
  slno,
  shortened_url_action,
  shortened_url,
  name,
  target_url_action,
  target_url,
  creator_name,
  creator_mobile,
  remarks,
  created_at,
  source,
  medium,
  campaign,
  opened,
  opened_times,
  opened_first,
  opened_last,
  created_through,
  changed_times,
  changed_last,
  action,
};

export default colProps;
