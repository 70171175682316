import colProps from "../subDataParts/columnProps";

/**
 * Predefined columns for data representation.
 */
const columns = [
  colProps.slno,
  colProps.shortened_url_action,
  colProps.shortened_url,
  colProps.name,
  colProps.target_url_action,
  colProps.target_url,
  colProps.creator_name,
  colProps.creator_mobile,
  colProps.remarks,
  colProps.created_at,
  colProps.source,
  colProps.medium,
  colProps.campaign,
  colProps.opened,
  colProps.opened_times,
  colProps.opened_first,
  colProps.opened_last,
  colProps.created_through,
  colProps.changed_times,
  colProps.changed_last,
  colProps.action,
];

export default columns;
