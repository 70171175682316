import { urls, getUrlHeaders, urlMethods } from "../config/urlConfig";
import { responseCheck } from "../utils/commonUtils";

/**
 * Fetches the UTM parameter list from the specified URL.
 * @returns {Promise<Object>} A promise that resolves to the JSON response.
 * @throws {Error} Throws an error if the network response is not successful.
 */
const fetchUtmParamList = async () => {
  try {
    const response = await fetch(urls.UTM_PARAMS_LIST_URL, {
      method: urlMethods.GET,
      headers: getUrlHeaders(),
    });
    responseCheck({response});
    return await response.json();
  } catch (error) {
    console.error("Error during get request:", error.message);
    return {}
  }
};

export default fetchUtmParamList;
