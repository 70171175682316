/**
 * Represents a filter property for column filtering.
 *
 * @typedef {Object} FilterProperty
 * @property {string} name - The name of the property.
 * @property {string} type - The type of the property (e.g., string, select, boolean, date).
 * @property {string} value - The value associated with the property.
 * @property {string} operator - The operator for comparison (e.g., contains, inlist, eq, inrange).
 */

/**
 * Filter property for the shortened URL.
 * @type {FilterProperty}
 */
const shortened_url = {
  name: "shortened_url",
  type: "string",
  value: "",
  operator: "contains",
};

/**
 * Filter property for the target URL.
 * @type {FilterProperty}
 */
const target_url = {
  name: "target_url",
  type: "string",
  value: "",
  operator: "contains",
};

/**
 * Filter property for the name.
 * @type {FilterProperty}
 */
const name = {
  name: "name",
  type: "string",
  value: "",
  operator: "contains",
};

/**
 * Filter property for the source.
 * @type {FilterProperty}
 */
const source = {
  name: "source",
  type: "select",
  value: "",
  operator: "inlist",
};

/**
 * Filter property for the medium.
 * @type {FilterProperty}
 */
const medium = {
  name: "medium",
  type: "select",
  value: "",
  operator: "inlist",
};

/**
 * Filter property for the campaign.
 * @type {FilterProperty}
 */
const campaign = {
  name: "campaign",
  type: "select",
  value: "",
  operator: "inlist",
};

/**
 * Filter property for the opened status.
 * @type {FilterProperty}
 */
const opened = {
  name: "opened",
  type: "boolean",
  operator: "eq",
};

/**
 * Filter property for the creation date.
 * @type {FilterProperty}
 */
const created_at = {
  name: "created_at",
  type: "date",
  value: {start: "", end: ""},
  operator: "inrange",
};

/**
 * Filter property for the creator's name.
 * @type {FilterProperty}
 */
const creator_name = {
  name: "creator_name",
  type: "string",
  value: "",
  operator: "contains",
};

/**
 * Filter property for the creator's mobile.
 * @type {FilterProperty}
 */
const creator_mobile = {
  name: "creator_mobile",
  type: "string",
  value: "",
  operator: "contains",
};

/**
 * Filter property for the creation through.
 * @type {FilterProperty}
 */
const created_through = {
  name: "created_through",
  type: "string",
  value: "",
  operator: "contains",
};

/**
 * Filter property for the remarks.
 * @type {FilterProperty}
 */
const remarks = {
  name: "remarks",
  type: "string",
  value: "",
  operator: "contains",
};

/**
 * Object containing all column filter properties.
 * @type {Object.<string, FilterProperty>}
 */
const colFilterProps = {
  shortened_url,
  target_url,
  name,
  source,
  medium,
  campaign,
  opened,
  created_at,
  creator_name,
  creator_mobile,
  created_through,
  remarks,
};

export default colFilterProps;
