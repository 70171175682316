import React from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import App from "./pages/App";

const Root = () => {
  const handleLogout = () => {
    localStorage.removeItem("tokenv2");
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            localStorage.getItem("tokenv2") ? (
              <App onLogout={handleLogout} />
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />
        <Route
          path="/login"
          element={
            localStorage.getItem("tokenv2") ? (
              <Navigate replace to="/" />
            ) : (
              <LoginPage />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default Root;

const root = createRoot(document.getElementById("root"));
root.render(<Root />);
