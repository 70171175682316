import moment from "moment";
import fetchUtmParamList from "../services/getUtmParamList";
import DeleteLink from "../components/microComponents/DeleteLink";
import EditLink from "../components/microComponents/EditLink";

/**
 * Formats the creation date using the provided date format.
 * @param {Object} params - The parameters object.
 * @param {string} params.value - The date value.
 * @param {string} params.dateFormat - The date format.
 * @returns {string} - Formatted date.
 */
const createdAtFormatter = ({ value }) =>
  moment(value).format("YYYY-MM-DD hh:mm:ss");

/**
 * Formats the last opened date.
 * @param {Object} params - The parameters object.
 * @param {Array} params.dates - The array of dates.
 * @returns {string} - Formatted last opened date.
 */
const openedChangedDateFormatter = ({ dates }) =>
  dates?.length
    ? new Date(dates[dates.length - 1]).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h24",
      })
    : "";

/**
 * Formats the first opened date.
 * @param {Object} params - The parameters object.
 * @param {Array} params.dates - The array of dates.
 * @returns {string} - Formatted first opened date.
 */
const openedFirstFormatter = ({ dates }) =>
  dates?.length
    ? new Date(dates[0]).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h24",
      })
    : "";

/**
 * Updates the UTM parameter lists for the specified columns.
 * @param {Object} params - The parameters object.
 * @param {Array} params.cols - The array of columns.
 * @returns {Array} - Updated columns.
 */
const updateUtmLists = async ({ cols }) => {
  const utmParamLists = await fetchUtmParamList();
  const { source = [], medium = [], campaign = [] } = utmParamLists || {};

  updateFilterEditorProps(cols, "source", source);
  updateFilterEditorProps(cols, "medium", medium);
  updateFilterEditorProps(cols, "campaign", campaign);

  return [...cols];
};

/**
 * Updates the render function for the "action" column *
 * @param {Object} params - The parameters object.
 * @param {Array} params.cols - The array of columns in the data grid.
 * @param {Function} params.editInitValSetter - A function to set initial values for editing.
 * @param {Function} params.editModalOpenner - A function to open the edit modal.
 * @returns {Array} - Updated array of columns with modified "action" column.
 */
const updateEditDeleteActions = ({
  cols,
  editInitValSetter,
  editModalOpenner,
  deleteIdSetter,
  deleteAlertOpenner,
}) => {
  const index = cols.findIndex((item) => item.name === "action");
  cols[index].render = ({ data }) => {
    const isCreator = data.creator_mobile === localStorage.getItem("userv2");
    const editHandler = () => {
      editInitValSetter(data);
      editModalOpenner(true);
    };
    const deleteHandler = () => {
      deleteIdSetter(data.id);
      deleteAlertOpenner(true);
    };
    return (
      <>
        {isCreator && (
          <span onClick={editHandler}>
            <EditLink />
          </span>
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {isCreator && (
          <span onClick={deleteHandler}>
            <DeleteLink />
          </span>
        )}
      </>
    );
  };
  return [...cols];
};

/**
 * Updates the filter editor props for the specified column.
 * @param {Array} cols - The array of columns.
 * @param {string} columnName - The name of the column.
 * @param {Array} dataSource - The array of data source.
 */
const updateFilterEditorProps = (cols, columnName, dataSource) => {
  const index = cols.findIndex((item) => item.name === columnName);
  cols[index].filterEditorProps.dataSource = dataSource.map((c) => ({
    id: c,
    label: c,
  }));
};

export {
  createdAtFormatter,
  openedFirstFormatter,
  openedChangedDateFormatter,
  updateUtmLists,
  updateEditDeleteActions,
};
